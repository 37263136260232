<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<UpdateFormContainer v-model:getAnsweredForm.sync="getAnsweredForm" v-model:isPageLoading.sync="isPageLoading"/>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonPage, IonContent, toastController } from '@ionic/vue';
import UpdateFormContainer from '@/components/UpdateFormContainer.vue';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

export default  {
	name: 'updateDeclarationForm',
	components: { UpdateFormContainer, IonPage, IonContent },
  data() {
    return {
      getAnsweredForm: {},
			isPageLoading: true
    }
  },
	watch: {
    '$route': function (to, from){
      if (to.name === "updateDeclarationForm" && from.name === 'dashboard') {
				window.scrollTo(0,0);

				this.$router.go(to.name);
				this.isPageLoading = true;
        this.getAnsweredQuestionnaire();
      }
    }
  },
  async beforeCreate() {
    let getProp = this.$route.params;
    let getId = parseInt(getProp.form_id)

    await store.dispatch('questionnaire/getAnsweredForm', getId).then(res => {
      this.getAnsweredForm = res;
			this.isPageLoading = false;
    }).catch(err => {
      this.$router.push('/dashboard');
			this.isPageLoading = false;

			if (err.status == 401) {
				localStorage.setItem('is_session_expired', true);
        localStorage.removeItem("is_consent_agreed");
        VueCookies.remove("misc");
				VueCookies.remove("misc-hs");
        window.location.href = '/login';
      }
    });
  },
	async created () {
		window.scrollTo(0,0);
		let parsed_user = {};
		let bytes = "";
    let decryptedData = "";

    let cipherData = VueCookies.get('misc');

		if (cipherData === null  || cipherData === undefined || cipherData === '') {
			VueCookies.remove('misc');
			VueCookies.remove("misc-hs");
			window.location.href = "/login";
		} else {
			bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) {
				let decode = JSON.parse(decryptedData);
        let user = decode.user;

				//Check if user exists
				if (user) {
					if (user.employee_no === null && user.employee_no === "" && user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
				} else {
					//Run verify auth if data does not exist
					await store.dispatch('login/verifyAuth').then(() => {
						parsed_user = JSON.parse(store.getters['login/currUser']);
						return;
					}).catch(err => {
						if (err.status == 401) {
							localStorage.setItem('is_session_expired', true);
							localStorage.removeItem("is_consent_agreed");
							VueCookies.remove("misc");
							window.location.href = '/login';
						}
					});

					if (parsed_user.employee_no === null && parsed_user.employee_no === "" && parsed_user.employee_no === undefined) {
						this.$router.push('/onboard')
					} else {
						return;
					}
				}
			} else {
				localStorage.setItem('is_session_expired', true);
				localStorage.removeItem("is_consent_agreed");
				VueCookies.remove("misc");
				VueCookies.remove("misc-hs");
				window.location.href = '/login';
			}
		}
	},
	methods: {
		async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
					cssClass: 'toast-custom',
					end: true
				})
			return toast.present();
		},
		async getAnsweredQuestionnaire() {
			let getProp = this.$route.params;
			let getId = parseInt(getProp.form_id)

			await store.dispatch('questionnaire/getAnsweredForm', getId).then(res => {
				this.getAnsweredForm = res;
				this.isPageLoading = false;
			}).catch(err => {
				this.$router.push('/dashboard');
				this.isPageLoading = false;

				if (err.status == 401) {
					localStorage.setItem('is_session_expired', true);
					localStorage.removeItem("is_consent_agreed");
					VueCookies.remove("misc");
					VueCookies.remove("misc-hs");
					window.location.href = '/login';
				}
			});
		}
	}
}
</script>